import { memo, useState, useEffect } from 'react';
import { EditProjectModal, Step } from './EditProjectModal';
import DescriptionModal from './DescriptionModal';
import MoreProjectDetailsModal from './MoreProjectDetailsModal';
import ProjectContactsModal from '../contacts/ProjectContactsModal';
import useProject from '../../hooks/useProject';
import useProjectContacts from '../../hooks/useProjectContacts';
import { Project, ProjectContact } from '../../data/models';
import Button from '../shared/Button';
import useFeature from '../feature_flags/hooks/useFeature';

function ProjectDetailsCard() {
  const { project, setProject } = useProject();
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalStep, setEditModalStep] = useState<Step>('name');
  
  const {
    objectId,
    name,
    description,
    primary_contact,
    npdes_permit_no,
    ms4_operator,
    inspection_frequency,
    inspection_frequency_other,
    map: {
      location: { locality, postal_code, region, street_address },
      images: { large },
    },
    contract_length,
    contract_amount,
    contract_start,
    contract_end,
    completion_percentage,
    permissions
  } = project as any;

  const { contacts: projectContacts, loading: contactsLoading, reloadContacts } = useProjectContacts(objectId);

  const handleContactChange = () => {
    reloadContacts();
  };

  const hasContractDetails = (project: Project): boolean => {
    return (
      (project.contract_amount !== undefined && project.contract_amount > 0) ||
      (project.contract_length !== undefined &&
      project.contract_length > 0 &&
      project.contract_start !== undefined &&
      project.contract_end !== undefined)
    );
  };

  const descriptionOverflowed = (): boolean =>
    description && description.length > 118;

  const noReportingFieldsFilled = (): boolean =>
    !npdes_permit_no && !ms4_operator && !inspection_frequency;

  const setProjectAndHideModal = (newProject: any): void => {
    setProject?.({ ...newProject });
    setShowEditModal(false);
  };

  const setStepAndShowModal = (step: Step): void => {
    setEditModalStep(step);
    setShowEditModal(true);
  };

  const buttonText = primary_contact && projectContacts.length === 0
    ? 'Edit'
    : projectContacts.length > 0
    ? 'View All'
    : 'View / Edit Contacts';

  const contactsModalButtonClassName = (hasContacts: boolean): string => {
    return `focus:outline-none bg-white border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold cursor-pointer ${
      hasContacts ? 'px-2 py-1 group-hover:opacity-100 transition-opacity duration-300 opacity-0 text-xs' : 'px-5 py-2 group-hover:opacity-80 my-3 text-xs'
    }`;
  };

  const {
    announcementAvailable,
    announcementModal,
    enabled: qrCodeSharingEnabled,
    forceShowAnnouncement,
    beta,
  } = useFeature('qrcode_sharing');

  return (
    <>
      <div className="bg-white rounded-sm border border-gray-200 overflow-hidden text-black shadow-xl mb-6 rounded-lg">
        <div className="flex flex-col h-full">
          <div className="card-header">
            <p className="text-sm font-bold">Info</p>
            <div className="flex items-center">
              <button
                className="card-header-btn"
                type="button"
                onClick={() => setStepAndShowModal('name')}
              >
                Edit
              </button>
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div
              className="col-span-6 bg-cover bg-center group relative"
              key={`projectMap${objectId}`}
              style={{ backgroundImage: `url(${large})` }}
            >
              <button
                className="transition-opacity duration-300 focus:outline-none absolute top-0 right-0 text-xs mt-1 mr-1 group-hover:opacity-100 bg-white px-2 py-1 border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold hover:opacity-80 opacity-0 cursor-pointer"
                onClick={() => setStepAndShowModal('map')}
              >
                Edit
              </button>
            </div>
            <div className="col-span-6 p-5 text-xs">
              <div className="group relative">
                <h2 className="text-md md:text-lg font-oswald uppercase font-medium">
                  {name}
                </h2>
                <p>{street_address}</p>
                <p>
                  {locality}, {region} {postal_code}
                </p>
                <button
                  className={`transition-opacity duration-300 text-xs focus:outline-none bg-white border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold hover:opacity-80 cursor-pointer ${
                    description
                      ? 'px-2 py-1 group-hover:opacity-100 opacity-0 -mt-2 absolute top-0 right-0'
                      : 'px-5 py-2 '
                  }`}
                  onClick={() => {
                    setStepAndShowModal('name');
                  }}
                >
                  {description ? 'Edit' : '+ Add Description'}
                </button>
              </div>
              <div className="group">
                <div className={`mt-5 flex ${primary_contact || projectContacts.length > 0 ? 'items-center justify-between space-x-2' : 'items-start flex-col'}`}>
                  <p className="font-bold">Contacts:</p>
                  <ProjectContactsModal
                    title="Manage Project Contacts"
                    afterUpdate={setProjectAndHideModal}
                    primaryContact={primary_contact}
                    setProjectAndHideModal={setProjectAndHideModal}
                    handleContactChange={handleContactChange}
                    button={
                      <Button
                        size="xs"
                        color="light"
                        text={buttonText}
                        data-testid="openContactsModal"
                        className={contactsModalButtonClassName(!!primary_contact || projectContacts.length > 0)} />
                    }
                  />
                </div>
                {(primary_contact || projectContacts.length > 0) && (
                  <div className="flex flex-wrap flex-col">
                    {primary_contact && (
                      <div key={primary_contact.objectId} className="flex">
                        <p className="text-xs font-semibold">
                          {primary_contact.name}<span className="text-gray-600 font-semibold ml-1">- Primary Contact</span>
                        </p>
                      </div>
                    )}
                    {projectContacts.map((contact: ProjectContact) => (
                      <div key={contact.id} className="flex">
                        <p className="text-xs font-semibold">
                          {contact.contact_name}{contact.role && (<span className="text-gray-600 font-semibold ml-1">- {contact.role}</span>)}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="group relative">
                <p className="font-bold mt-5 mb-2">Description:</p>
                {description && (
                  <div
                    className={`${
                      descriptionOverflowed() && ''
                    } transition-all duration-300 group `}
                  >
                    <p className="line-clamp-3">{description}</p>
                    {descriptionOverflowed() && (
                      <DescriptionModal description={description} />
                    )}
                  </div>
                )}
                <button
                  className={`transition-opacity duration-300 text-xs focus:outline-none bg-white border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold hover:opacity-80 cursor-pointer ${
                    description
                      ? 'px-2 py-1 group-hover:opacity-100 opacity-0 -mt-2 absolute top-0 right-0'
                      : 'px-5 py-2 '
                  }`}
                  onClick={() => {
                    setStepAndShowModal('name');
                  }}
                >
                  {description ? 'Edit' : '+ Add Description'}
                </button>
              </div>
              <MoreProjectDetailsModal
                length={contract_length}
                amount={contract_amount}
                start_date={contract_start}
                end_date={contract_end}
                completion_percentage={completion_percentage}
                npdes_permit_no={npdes_permit_no}
                ms4_operator={ms4_operator}
                inspection_frequency={inspection_frequency}
                inspection_frequency_other={inspection_frequency_other}
                noReportingFieldsFilled={noReportingFieldsFilled}
                setStepAndShowModal={setStepAndShowModal}
                hasContractDetails={hasContractDetails(project as Project)}
                permissions={permissions}
              />
            </div>
          </div>
        </div>
      </div>
      {showEditModal && (
        <EditProjectModal
          key={`project_${objectId}_edit_modal`}
          step={editModalStep}
          setStep={setEditModalStep}
          project={project}
          closeWithoutSaving={() => setShowEditModal(false)}
          afterSave={setProjectAndHideModal}
          hasContractDetails={hasContractDetails(project as Project)}
        />
      )}
      {announcementAvailable && announcementModal}
    </>
  );
}

export default memo(ProjectDetailsCard);
