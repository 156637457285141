import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useEffect, useRef, useState } from 'react';
import { environment } from '../../../config';
import MapBoxMapTypeToggle from '../../map_layers/mapbox/MapBoxMapTypeToggle';
import MapBoxMapLayers from '../../map_layers/mapbox/MapBoxMapLayers';
import * as turf from '@turf/turf';
import useMapLock from './hooks/useMapLock';
import useMapControls from './hooks/useMapControls';
import useMapEventsForLoadingState from './hooks/useMapEventsForLoadingState';
import { mapboxStyleUrls } from '../../../data/models';

mapboxgl.accessToken = environment.mapBoxPublicKey;
const DEFAULT_HEIGHT = 525;
const DEFAULT_WIDTH = 525;
const svgConfig = {
  circle: {
    path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    anchor: { x: 270, y: 290 },
    strokeWidth: '30px',
    scaledWidth: 525,
    scaledHeight: 525,
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT,
    viewBox: `0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`,
    includeText: false,
  },
  triangle: {
    path: 'M490,474.459H0L245.009,15.541L490,474.459z',
    anchor: { x: 260, y: 330 },
    strokeWidth: '30px',
    scaledWidth: 525,
    scaledHeight: 525,
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT,
    viewBox: `0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`,
    includeText: false,
  },
  plus: {
    path: 'M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z',
    anchor: { x: 230, y: 250 },
    strokeWidth: '30px',
    scaledWidth: 525,
    scaledHeight: 525,
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT,
    viewBox: `0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`,
    includeText: false,
  },
  pin: {
    path: 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z',
    anchor: { x: 9, y: 12 },
    strokeWidth: '1px',
    height: `100%`,
    width: `30px`,
    viewBox: '3 0 18 24',
    includeText: true,
  },
  orange: {
    path: 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z',
    anchor: { x: 9, y: 12 },
    strokeWidth: '1px',
    height: `100%`,
    width: `30px`,
    viewBox: '3 0 18 24',
    includeText: true,
  },
};

const buildPinSvg = ({ color, index, innerHTML = null, pinStyle, scale, type }) => {
  const svg =
    pinStyle?.icon !== 'inherit' ? svgConfig[pinStyle.icon] : svgConfig[type];
  const fillColor = pinStyle.color === 'inherit' ? color : pinStyle.color;
  const icon = {
    path: svg.path,
    fillColor,
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    anchor: svg.anchor,
  };

  const pathAttributes = {
    ...icon,
    d: icon.path,
    scale: scale,
  };

  const svgAttributes = {
    display: 'block',
    height: svg.scaledHeight ? `${svg.scaledHeight * scale}px` : svg.height,
    width: svg.scaledWidth ? `${svg.scaledWidth * scale}px` : svg.width,
    viewBox: svg.viewBox,
    fill: icon.fillColor,
    stroke: '#fff',
    'stroke-width': svg.strokeWidth,
  };

  const _div = window.document.createElement('div');
  const _svg = window.document.createElementNS(
    'http://www.w3.org/2000/svg',
    'svg'
  );
  const _path = window.document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path'
  );
  const _text = window.document.createElementNS(
    'http://www.w3.org/2000/svg',
    'text'
  );

  for (const name of Object.keys(svgAttributes)) {
    _svg.setAttributeNS(null, name, svgAttributes[name]);
  }
  _div.appendChild(_svg);

  for (const name of Object.keys(pathAttributes)) {
    _path.setAttributeNS(null, name, pathAttributes[name]);
  }
  _svg.appendChild(_path);

  if (svg.includeText) {
    const textAttributes = {
      'text-anchor': 'middle',
      x: '66%',
      y: '50%',
      'font-size': '8px',
      'font-weight': 'normal',
    };

    for (const name of Object.keys(textAttributes)) {
      _text.setAttributeNS(null, name, textAttributes[name]);
    }

    if (innerHTML) _text.innerHTML = innerHTML;
    else if (parseInt(index) > -1) _text.innerHTML = index.toString();

    _svg.appendChild(_text);
  }

  return _div;
};

export {
  buildPinSvg
}

class MapMarker {
  mapboxMarker;
  objectId;

  constructor(_mapboxMarker, _objectId) {
    this.mapboxMarker = _mapboxMarker;
    this.objectId = _objectId;
  }
}

export default function MapBoxMap({
  bearing = 0,
  children,
  pitch = 0,
  project,
  pins,
  passedSelectedPin,
  mapState,
  onSelectPin,
  onMapClick,
  onBoundsUpdated,
  onPitchUpdated,
  onBearingUpdated,
  onLayersChosen,
  pinStyle = {
    icon: 'inherit',
    color: 'inherit',
    showCount: false,
  },
  fitToPins,
  locked,
  defaultBounds,
  defaultLayers = [],
  allowMapLayerSelection = true,
  canAddLayers = true,
  mapTypeToggle = true,
  showNavigationControls = true,
  canEditLayers = true,
  testId = 'mapBoxMapContainer',
  zoomOnSelect = true,
  userLocation = true,
}) {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [allPinMarkers, setAllPinMarkers] = useState([]);
  const [lastSelectedPin, setLastSelectedPin] = useState(null);
  const [bounds, setBounds] = useState();

  const [canRender, setCanRender] = useState(false);

  function removeMarkers() {
    if (!allPinMarkers.length) return;
    for (const marker of allPinMarkers) {
      marker.mapboxMarker.remove();
    }
    setAllPinMarkers([])
  }

  function removeMarker(objectId) {
    let target = allPinMarkers.find((marker) => objectId == marker.objectId);
    if (!target) return;
    target.mapboxMarker.remove();
  }

  function addMarker(pin, scale = 0.05) {
    const {
      objectId,
      color,
      coordinate: { lat, lng },
      pin_type: {
        pin_color,
        icon: { name },
      },
      index,
    } = pin;

    const marker = createNewPinMarker({
      type: name,
      color: color || pin_color,
      coordinates: [lng, lat],
      objectId,
      index,
      scale,
    });

    return new MapMarker(marker, objectId);
  }

  function initializeMarkers() {
    const updatedMarkers = [];
    removeMarkers();
    for (const [index, pin] of pins.entries()) {
      updatedMarkers.push(addMarker({ ...pin, index: pin.index ?? index + 1 }));
    }
    setAllPinMarkers([...updatedMarkers]);
  }

  function layersChosen(layers) {
    onLayersChosen?.(layers);
  }

  useMapLock({ map: map.current, locked });
  useMapControls({ map: map.current, locked, showNavigationControls, userLocation });

  useEffect(() => {
    if (!pins || !map.current) return;

    initializeMarkers();

    if (
      pins.length > 0 &&
      fitToPins &&
      (!defaultBounds?.west) &&
      !bounds
    )
      map.current.fitBounds(fitPins(), {
        bearing,
        pitch,
      });
  }, [JSON.stringify(pinStyle), pins?.map(({ objectId }) => objectId).join(',')]);

  const createNewPinMarker = ({
    type,
    color,
    coordinates,
    objectId,
    index = -1,
    scale = 0.05,
  }) => {
    const marker = new mapboxgl.Marker({
      element: buildPinSvg({ type, color, index, pinStyle, scale }),
      anchor: pinStyle?.icon === 'pin' ? 'bottom' : undefined,
    })
      .setLngLat(coordinates)
      .addTo(map.current);

    registerMarkerEventListeners({ marker, coordinates, objectId });

    return marker;
  };

  const registerMarkerEventListeners = ({ marker, objectId }) => {
    const el = marker.getElement();

    el.addEventListener('click', () => {
      if (!map.current) return;
      onSelectPin(objectId);
    });
  };

  // Have to pass mapstate to child component so event listener can be updated dynamically
  // State changes arent tracked by mapbox event listeners
  // https://github.com/mapbox/mapbox-gl-js/issues/9627
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    const listener = (e) => onMapClick?.(e);
    map.current.on('click', listener);
    map.current.getCanvas().style.cursor =
      mapState == 'readyToDrop' ? 'crosshair' : 'grab';
    return () => map.current.off('click', listener);
  }, [mapState]);

  function fitPins() {
    if (pins.length == 1)
      return turf.bbox(
        turf.buffer(
          new turf.point([pins[0].coordinate.lng, pins[0].coordinate.lat]),
          0.05
        )
      );

    let pointCollection = new turf.featureCollection(
      pins.map((pin) => {
        return new turf.point([pin.coordinate.lng, pin.coordinate.lat]);
      })
    );

    let envelope = turf.buffer(turf.envelope(pointCollection), 0.05);

    return turf.bbox(envelope);
  }

  useEffect(() => {
    if (map.current) return; // initialize map only once

    let startBounds = null

    if (defaultBounds?.west) {
      startBounds = [
        [defaultBounds.west, defaultBounds.south],
        [defaultBounds.east, defaultBounds.north],
      ];
    }
    else if (fitToPins && pins.length > 0) {
      startBounds = fitPins();
    }
    else if (project) {
      startBounds = [
        [project.bounding_box.west, project.bounding_box.south],
        [project.bounding_box.east, project.bounding_box.north],
      ];
    }

    map.current = new mapboxgl.Map({
      bearing,
      container: mapContainer.current,
      style: mapboxStyleUrls['satellite-streets'],
      bounds: startBounds,
      preserveDrawingBuffer: true,
      pitch,
    });

    map.current.on('load', () => {
      setCanRender(true);
      map.current.resize();

      if (pins?.length > 0) initializeMarkers();

      map.current.on('rotateend', ({ target }) => {
        onBearingUpdated?.(target.getBearing());
      });
      map.current.on('pitchend', ({ target }) => {
        onPitchUpdated?.(target.getPitch());
      });

      map.current.on('moveend', ({ originalEvent, target }) => {
        const newBounds = {
          north: target.getBounds()._ne.lat,
          west: target.getBounds()._sw.lng,
          south: target.getBounds()._sw.lat,
          east: target.getBounds()._ne.lng,
        };
        setBounds(newBounds);
        if (originalEvent && originalEvent.type !== 'resize') {
          onBoundsUpdated?.(newBounds);
        }
      });
    });
  }, [map.current]);

  useEffect(() => {
    if (passedSelectedPin != null) {
      setLastSelectedPin(passedSelectedPin);

      if (zoomOnSelect) {
        removeMarker(passedSelectedPin.objectId);
        setAllPinMarkers(
          [...allPinMarkers.map((marker) => {
            if (marker.objectId != passedSelectedPin.objectId) return marker;
            else return addMarker(passedSelectedPin, 0.07);
          })]
        );

        map.current.flyTo({
          center: [
            passedSelectedPin.coordinate.lng,
            passedSelectedPin.coordinate.lat,
          ]
        });
      }
    } else if (lastSelectedPin != null) {
      removeMarker(lastSelectedPin.objectId);
      setAllPinMarkers([
        ...allPinMarkers.map((marker) => {
          if (marker.objectId != lastSelectedPin.objectId) return marker;
          else return addMarker(lastSelectedPin);
        })
      ]);
      setLastSelectedPin(null);
    }
  }, [passedSelectedPin]);

  useMapEventsForLoadingState({ map: map.current, enable: true, mapContainer: mapContainer.current });

  return (
    <>
      {children}
      {canRender && (
        <MapBoxMapLayers
          map={map.current}
          onLayersChosen={layersChosen}
          defaultLayers={defaultLayers}
          canToggleLayers={allowMapLayerSelection}
          canAddLayers={canAddLayers}
          canEditLayers={canEditLayers}
        />
      )}
      {mapTypeToggle && <MapBoxMapTypeToggle map={map.current} />}
      <div
        ref={mapContainer}
        id="mapContainer"
        data-testid={testId}
        className="w-full h-full"
      />
    </>
  );
}
