import { useRef } from 'react';
import { QRCode } from 'react-qrcode-logo';
import useWorkspaceContext from '../../hooks/useWorkspaceContext';
import { Project } from '../../data/models';
import useApi from '../../hooks/useApi';
import { useEffect } from 'react';
import accessCodesApi from '../../api/access_codes';
import logo from '../../images/icons/icon-circle-canvas.png';
import { useReactToPrint } from 'react-to-print';
import Button from '../shared/Button';
import Loader from '../utility/Loader';

export default function ProjectQRCode({ onAccessCode }: {
  onAccessCode?: (accessCode: string) => void
}) {
  const { workspace } = useWorkspaceContext();
  const {
    data: { records: access_codes },
    loading,
    request: getAccessCodes,
  } = useApi(accessCodesApi.getAccessCodes, { records: [] });

  const accessCodeSearchParams = () => {
    let params: { where: { active: boolean }; workspace_id?: number } = { where: { active: true } };
    if (workspace) params.workspace_id = workspace.id;
    return params;
  };

  useEffect(() => {
    if (!workspace) return;
    getAccessCodes(accessCodeSearchParams());
  }, [workspace]);

  useEffect(() => {
    if (!access_codes.length) return;
    onAccessCode?.(access_codes[0].code)
  }, [access_codes])

  const origin = window.location.origin;
  const inviteUrl = access_codes[0]
    ? `${origin}/onboarding/sign_up?code=${access_codes[0].code}&source=conference_qr`
    : 'https://app.sitemarker.com/onboarding/sign_up?code=80AEE4&source=conference_qr';
  const componentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentToPrint.current,
  });

  return <div>
    <div className="flex justify-center mb-4 py-5" data-testid="qrCodeContainer" ref={componentToPrint}>
      <div className="border p-2 rounded-md shadow">
        <QRCode
          value={inviteUrl}
          size={256}
          logoImage={logo}
          logoWidth={50}
          logoHeight={50}
          logoPadding={8}
          quietZone={20}
          logoPaddingStyle="circle"
          removeQrCodeBehindLogo
        />
      </div>
    </div>
    <p className="mt-2 text-sm text-gray-600">Scan to join the project</p>
    <Button
      data-testid="qrCodePrintButton"
      text="Print QR Code"
      color="light"
      onClick={handlePrint}
      className="mt-4"
    />
  </div>;
}