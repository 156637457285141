import { useEffect } from 'react';
import qrLogo from '../images/icons/icon-circle-canvas.png';
import useApi from '../hooks/useApi';
import sharesApi from '../api/shares';
import { Redirect, useParams } from 'react-router-dom';
import Loader from '../partials/utility/Loader';
import useAuth from '../auth/useAuth';
import { QRCode } from 'react-qrcode-logo';

function ShareQRCode() {
    const {
        data: share,
        error,
        loading,
        request: getShare,
    } = useApi(sharesApi.getShare, null, true);
    const { share_id } = useParams<{ share_id: string }>();
    const { user } = useAuth();

    useEffect(() => {
        getShare(share_id);
    }, []);

    if (loading)
        return (
            <div className="flex py-5 justify-center" data-testid="loader-share">
                <Loader color="black" />
            </div>
        );

    if (share?.archived_at || !share)
        return (
            <Redirect
                to={{
                    pathname: user
                        ? `/${user.default_workspace?.objectId}/projects`
                        : '/signin',
                    state: {
                        info: 'Sorry looks like that link is no longer valid',
                        dismissable: user ? true : false,
                    },
                }}
            />
        );

    return <div className="flex flex-col items-center justify-center mb-4 py-5" >
        <QRCode
            value={`${share.link}?utm_source=qr_code_mobile_scan`}
            size={256}
            logoImage={qrLogo}
            logoWidth={75}
            logoHeight={75}
            logoPadding={8}
            quietZone={20}
            logoPaddingStyle="circle"
            removeQrCodeBehindLogo
        />

        <div className="flex flex-col items-center justify-center mt-10 px-8">
            <p className="font-medium text-secondary text-center">Scan this QR code and use it on site to <em>link directly to reports and documents.</em> </p>
        </div>
    </div>;

}

export default ShareQRCode;
