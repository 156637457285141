import { Redirect, useParams } from 'react-router-dom';
import Page, { PageHeader, PageTitle } from '../partials/shared/Page';
import useWorkspace from '../partials/workspaces/hooks/useWorkspace';
import useAuth from '../auth/useAuth';
import SettingsSidebar from '../partials/settings/SettingsSidebar';
import useQuery from '../hooks/useQuery';
import { ModalFooter } from '../partials/utility/Modal';
import capitalize from '../utility/capitalize';
import { ArchiveTabState, PinType, ReportType, User, Workspace, WorkspaceMember } from '../data/models';
import { useEffect, useState } from 'react';
import Button from '../partials/shared/Button';
import useWorkspaceMembers from '../partials/workspaces/hooks/useWorkspaceMembers';
import Table from '../utils/Table';
import { H2 } from '../utils/Typography';
import Loader, { LoaderContainer } from '../partials/utility/Loader';
import humanize from '../utility/humanize';
import { UserInfo, UserPhoto } from '../partials/users/UsersTableItem';
import { Grid, Row } from '../utils/Layout';
import EditPermissionsModal from '../partials/team/EditPermissionsModal';
import useWorkspacePinTypes from '../partials/pin_types/hooks/useWorkspacePinTypes';
import PinTypeEditModal from '../partials/pin_types/PinTypeEditModal';
import PinTypeCard from '../partials/pin_types/PinTypeCard';
import Tabs, { Tab } from '../utils/Tabs';
import WorkspaceForm from '../partials/workspaces/WorkspaceForm';
import NotificationsPanel from '../partials/users/NotificationsPanel';
import AppsPanel from '../partials/workspaces/AppsPanel';
import PlansPanel from '../partials/workspaces/PlansPanel';
import BillingPanel from '../partials/billing/BillingPanel';
import FeedbackPanel from '../partials/users/FeedbackPanel';
import useWorkspaceReportTypes from '../partials/report_types/hooks/useWorkspaceReportTypes';
import ReportTypeCard from '../partials/report_types/ReportTypeCard';
import useReportTypes from '../partials/report_types/hooks/useReportTypes';
import ReportTypeDuplicateModal from '../partials/report_types/ReportTypeDuplicateModal';

export default function WorkspaceEdit() {

    const { permissions, user } = useAuth();
    const query = useQuery();
    const { workspaceId } = useParams<{ workspaceId: string }>();
    const { workspace, loading } = useWorkspace(workspaceId);
    const activeTab = query.get('active') || 'general';
    const ActiveTab = TabComponents[activeTab] || (() => <EmptySettings>
        <SettingsTitle>{capitalize(activeTab)}</SettingsTitle>
    </EmptySettings>);

    if (loading) return <LoaderContainer>
        <Loader className='text-gray-800' />
    </LoaderContainer>;

    if (!permissions?.can?.('admin')) {
        return <Redirect to={`/${user.default_workspace?.objectId}/projects`} />
    }

    return <Page>
        <PageHeader>
            <PageTitle>
                Edit {workspace.name}
            </PageTitle>
        </PageHeader>
        <SettingsCard>
            <SettingsSidebar selected={activeTab} />
            <ActiveTab />
        </SettingsCard>
    </Page>
}

const GeneralSettings = () => {
    const { permissions } = useAuth();
    const { workspaceId } = useParams<{ workspaceId: string }>();
    const { workspace, updated, reloadWorkspace, saveWorkspace, updating } = useWorkspace(workspaceId);
    const [workspaceAttributes, setWorkspaceAttributes] = useState<Workspace | any>(workspace);

    const updateAttributes = (key: string, value: any) => {
        setWorkspaceAttributes((wa: any) => ({ ...wa, [key]: value }));
    };

    const _saveWorkspace = () => {
        saveWorkspace(workspaceAttributes);
    }

    useEffect(() => {
        setWorkspaceAttributes(workspace);
    }, [workspace?.updated_at]);

    useEffect(() => {
        if (!updated) return;
        reloadWorkspace();
    }, [updated]);

    return <SettingsBody footer={workspace ?
        <ModalFooter sticky>
            <Button
                onClick={_saveWorkspace}
                text="Save"
                color="success"
                disabled={updating}
                saving={updating}
                saved={updated}
                savedText={'Saved'}
                data-testId={`savedWorkspaceButton${workspace?.name}`}
            />
        </ModalFooter> : null
    }>
        <SettingsTitle>General</SettingsTitle>
        <WorkspaceForm
            workspace={workspaceAttributes}
            onUpdate={updateAttributes}
            permissions={permissions}
        />
    </SettingsBody>
}

const MemberSettings = () => {
    const { workspaceId } = useParams<{ workspaceId: string }>();
    const { members, loading } = useWorkspaceMembers(workspaceId);

    return <SettingsBody>
        <SettingsTitle>Members</SettingsTitle>
        <Table.Table>
            <Table.Header>
                <H2>Members</H2>
            </Table.Header>
            <Table.Body>
                <Table.Thead>
                    <Table.Th>User</Table.Th>
                    <Table.Th>Role</Table.Th>
                </Table.Thead>
                <Table.Tbody>
                    {members.map((workspaceMember: WorkspaceMember) => {
                        return <Table.Tr>
                            <Table.Td>
                                <Row>
                                    <UserPhoto user={workspaceMember.member as User} />
                                    <UserInfo user={workspaceMember.member as User} />
                                </Row>
                            </Table.Td>
                            <Table.Td>
                                <EditPermissionsModal
                                    workspaceId={workspaceId}
                                    workspaceMemberId={workspaceMember.id}
                                    member={workspaceMember.member as User}
                                    role={workspaceMember.role}
                                    button={
                                        <p className="border text-xs text-center rounded-md py-1 shadow-sm cursor-pointer">{humanize(workspaceMember.role.name.replace('workspace', ''))}</p>
                                    }
                                />
                            </Table.Td>
                        </Table.Tr>
                    })}
                </Table.Tbody>
            </Table.Body>
            {loading && <LoaderContainer>
                <Loader className='text-gray-800' />
            </LoaderContainer>}
        </Table.Table>
    </SettingsBody>
}

const PinTypesGrid = () => {

    const { workspaceId } = useParams<{ workspaceId: string }>();
    const { pinTypes, reloadPinTypes } = useWorkspacePinTypes(workspaceId, {
        params: { include_archived: true }
    });
    const [editingPinType, setEditingPinType] = useState<PinType | null>(null);
    const [activeTab, setActiveTab] = useState<ArchiveTabState>('active');
    const filteredPinTypes = pinTypes.filter((pt: PinType) => activeTab === 'active' ? !pt.archived_at : !!pt.archived_at);
    return <><div className="mb-8">
        <SettingsTitle>Pin types</SettingsTitle>
        <Tabs mb={4}>
            <Tab testId="pinTypesActive" active={activeTab === 'active'} onClick={() => setActiveTab('active')}>Active</Tab>
            <Tab testId="pinTypesArchived" active={activeTab === 'archived'} onClick={() => setActiveTab('archived')}>Archived ({pinTypes.filter((fo: PinType) => !!fo.archived_at).length})</Tab>
        </Tabs>
        {filteredPinTypes.length === 0 && <div className="p-2 text-center text-sm text-gray-800">No {activeTab} Pin type(s) found</div>}
        <Grid>
            {filteredPinTypes.map((pinType: PinType) => <PinTypeCard key={`pinTypeCard${pinType.objectId}`} pinType={pinType} onEdit={setEditingPinType} afterArchived={reloadPinTypes} />)}
        </Grid>
    </div>
        <PinTypeEditModal pinTypeId={editingPinType?.id || null} onClose={() => setEditingPinType(null)} />
    </>
}

const ReportTypesGrid = () => {
    const { workspaceId } = useParams<{ workspaceId: string }>();
    const { reportTypes, reloadReportTypes } = useWorkspaceReportTypes(workspaceId, {
        params: { include_archived: true }
    });

    const { reportTypes: mainReportTypes } = useReportTypes();

    const [addingReportType, setAddingReportType] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<ArchiveTabState>('active');
    const filteredReportTypes = reportTypes.filter((pt: PinType) => activeTab === 'active' ? !pt.archived_at : !!pt.archived_at);
    const availableMainReportTypes = mainReportTypes.filter((mrt: ReportType) => !reportTypes.find((rt: ReportType) => rt.duplicated_from_id === mrt.id));

    return <>
        <div className="mb-8">
            <div className="flex justify-between items-center">
                <SettingsTitle>
                    Report Types
                </SettingsTitle>
                <Button
                    disabled={!availableMainReportTypes.length}
                    text="+ Add"
                    size="sm"
                    color='light'
                    onClick={() => setAddingReportType(true)}
                />
            </div>
            <Tabs mb={4}>
                <Tab testId="reportTypesActive" active={activeTab === 'active'} onClick={() => setActiveTab('active')}>Active</Tab>
                <Tab testId="reportTypesArchived" active={activeTab === 'archived'} onClick={() => setActiveTab('archived')}>Archived ({reportTypes.filter((fo: PinType) => !!fo.archived_at).length})</Tab>
            </Tabs>
            {filteredReportTypes.length === 0 && <div className="p-2 text-center text-sm text-gray-800">No {activeTab} Report type(s) found</div>}
            <Grid>
                {filteredReportTypes.map((reportType: ReportType) => <ReportTypeCard key={`reportTypeCard${reportType.objectId}`} reportType={reportType} afterArchived={reloadReportTypes} />)}
            </Grid>
        </div>
        <ReportTypeDuplicateModal
            reportTypes={availableMainReportTypes}
            open={addingReportType}
            onClose={() => setAddingReportType(false)}
            afterDuplication={() => {
                setAddingReportType(false);
                reloadReportTypes();
            }}
        />
    </>
}

const PinTypeSettings = () => {

    return <>
        <SettingsBody>
            <PinTypesGrid />
            <ReportTypesGrid />
        </SettingsBody >
    </>
}

const TabComponents: { [key: string]: React.ComponentType } = {
    'general': GeneralSettings,
    'members': MemberSettings,
    'pin_types': PinTypeSettings,
    'notifications': NotificationsPanel,
    'integrations': AppsPanel,
    'plans': PlansPanel,
    'billing': BillingPanel,
    'feedback': FeedbackPanel
}

const EmptySettings = ({ children }: {
    children: React.ReactNode
}) => <SettingsBody>
        {children}
    </SettingsBody>

const SettingsCard = ({ children }: {
    children: React.ReactNode
}) => <div className="bg-white shadow-lg rounded-md mb-10 border border-gray-200">
        <div className="flex flex-col md:flex-row md:-mr-px">
            {children}
        </div>
    </div>

const SettingsBody = ({ children, footer }: {
    children: React.ReactNode,
    footer?: React.ReactNode
}) => <div className="flex-grow bg-gray-50">
        <div className="p-6">
            {children}
        </div>
        {footer}
    </div>

const SettingsTitle = ({ children }: {
    children: React.ReactNode
}) => <h2 className="text-2xl text-gray-800 font-bold mb-5">{children}</h2>