import { useState, useRef } from 'react';
import ProjectQRCode from '../project/ProjectQRCode';
import Modal, { ModalHeader, ModalBody, ModalFooter } from '../utility/Modal';
import Button from '../shared/Button';

const QRCodeModal = ({ onClose, open }: { onClose: () => void; open: boolean; }) => {
  const [copied, setCopied] = useState(false);
  const [accessCode, setAccessCode] = useState<string | null>(null);
  const linkRef = useRef<HTMLInputElement>(null);

  const copyToClipboard = async () => {
    if (linkRef.current) {
      try {
        await navigator.clipboard.writeText(linkRef.current.value);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    }
  };

  const qrLink = `${window.location.origin}/onboarding/sign_up?code=${accessCode}&source=conference_qr`;

  return (
    <Modal isOpen={open} onClose={onClose}  >
      <ModalHeader title="Project QR Code" onClose={onClose} />
      <ModalBody>
        <ProjectQRCode onAccessCode={setAccessCode} />
        <div className="my-4 flex items-center">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="mx-4 text-gray-500 text-lg font-medium">Or</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>
        <div className="mt-4">
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              name="qrLink"
              id="qrLink"
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
              value={qrLink}
              readOnly
              ref={linkRef}
            />
            <button
              type="button"
              className="inline-flex items-center px-3 py-2 border border-l-0 border-gray-300 rounded-r-md bg-gray-50 text-gray-500 text-sm"
              onClick={copyToClipboard}
            >
              {copied ? 'Copied!' : 'Copy'}
            </button>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button text="Close" onClick={onClose} />
      </ModalFooter>
    </Modal>
  );
};

export default QRCodeModal;