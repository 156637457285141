import client from './client';

const invited = (email) => client.post('/invited', { email });
const invitation = (user) => client.put('/invitation', { user });
const login = (email, password) =>
  client.post('/login', { user: { email, password } });
const signUp = (user) => client.post('/signup', { user: user });
const update = (user) => client.put('/signup', { user: user });
const me = () => client.get('/me');
const remove = () => client.delete('/signup');
const resetPassword = (email) => client.post('/password', { user: { email } });
const updatePassword = (user) => client.put('/password', { user });
const confirmEmail = (confirmation_token) =>
  client.put('/confirmation', { confirmation_token });
const omniauth = (provider, credential) =>
  client.post(`/auth/${provider}`, { credential });
const resendConfirmation = (email) =>
  client.post('/confirmation', { user: { email } });

export default {
  invited,
  invitation,
  login,
  signUp,
  update,
  omniauth,
  me,
  resetPassword,
  updatePassword,
  confirmEmail,
  resendConfirmation,
  remove,
};
