import { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import authApi from '../api/auth';
import useAuth from '../auth/useAuth';
import useQuery from '../hooks/useQuery';

import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import Loader from '../partials/utility/Loader';

import OnboardingImage from '../images/bgs/login-bg.png';
import SignupForm from '../partials/onboarding/SignupForm';
import DownloadAppPrompt from '../partials/onboarding/DownloadAppPrompt';
import EmailConfirmationScreen from '../partials/onboarding/EmailConfirmationScreen';
import { User } from '../data/models';
import useFeature from '../partials/feature_flags/hooks/useFeature';

import { useMsal } from "@azure/msal-react";
import { AuthenticationResult } from "@azure/msal-browser";

function ConferenceOnboarding() {
  const { step } = useParams<{ step: string }>();
  const history = useHistory();
  const { setUser, logIn } = useAuth();
  const query = useQuery();
  const code = query.get('code');
  const [signingUp, setSigningUp] = useState(false);
  const { enabled: googleEnabled } = useFeature('login_with_google');
  const { enabled: microsoftEnabled } = useFeature('login_with_microsoft');
  const [signupMethod, setSignupMethod] = useState<'email' | 'social' | null>(null);
  const isFromConference = query.get('source') === 'conference_qr';

  const handleSignupComplete = async (user: User) => {
    setUser(user);
    setSignupMethod('email');
    history.push('/onboarding/email-confirmation?source=conference_qr');
  };

  const handleOmniAuth = async (provider: string, credential: string) => {
    setSigningUp(true);

    try {
      const response = await authApi.omniauth(provider, credential);
      const loggedInUser = await logIn(response);
      setUser(loggedInUser);
      setSignupMethod('social');
      history.push('/onboarding/download-app?source=conference_qr');
    } catch (error: any) {
      return alert(error.errorMessage);
    } finally {
      setSigningUp(false);
    }
  };

  const handleGoogleLogin = ({ credential }: CredentialResponse) => {
    if (credential) {
      handleOmniAuth('google', credential);
    }
  };

  const { instance } = useMsal();
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        const result = await instance.handleRedirectPromise();
        if (result) {
          setIsAuthenticating(true);
          handleMicrosoftAuthResult(result);
        }
      } catch (error) {
        setIsAuthenticating(false);
        alert('An error occurred during Microsoft login. Please try again.');
      }
    };

    handleRedirectResult();
  }, [instance]);

  const handleMicrosoftAuthResult = (result: AuthenticationResult) => {
    const idToken = result.idToken;
    if (idToken) {
      handleOmniAuth('microsoft', idToken);
    } else {
      alert('Unable to complete Microsoft login. Please try again.');
    }
  };

  const handleMicrosoftLoginClick = async () => {
    setIsAuthenticating(true);
    try {
      if (window.innerWidth > 768) {
        const result = await instance.loginPopup();
        handleMicrosoftAuthResult(result);
      } else {
        await instance.loginRedirect();
      }
    } catch (error) {
      alert('An error occurred during Microsoft login. Please try again.');
    } finally {
      setIsAuthenticating(false);
    }
  };

  return (
    <main className="bg-white">
      <div className="relative flex">
        {/* Content */}
        <div className="w-full md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
              {/* Logo */}
              <Link className="block" to="/">
                <svg
                  width="54"
                  height="33"
                  viewBox="0 0 54 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2324 10.4287L26.7621 20.3574L43.3054 10.4287L36.8946 6.58489L30.0762 10.6732V2.48302L26.7621 0.5L23.4616 2.48302V10.6732L16.6433 6.57131L10.2324 10.4287Z"
                    fill="#F49F15"
                  />
                  <path
                    d="M53.4244 16.5L47.0135 12.6426L26.7622 24.7988L26.6264 24.7037L6.52454 12.6562L0.100098 16.5L26.7622 32.5L53.4244 16.5Z"
                    fill="#16697A"
                  />
                </svg>
              </Link>
            </div>

            {/* Progress bar */}
            <div className="px-4 pt-12 pb-8">
              <div className="max-w-md mx-auto w-full">
                <div className="relative">
                  <div
                    className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-gray-200"
                    aria-hidden="true"
                  ></div>
                  <ul className="relative flex justify-between w-full">
                    <li>
                      <div
                        className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${step === 'sign_up' || step === 'email-confirmation' || step === 'download-app'
                          ? 'bg-tertiary text-white'
                          : 'bg-gray-100 text-gray-500'
                          }`}
                      >
                        1
                      </div>
                    </li>
                    <li>
                      <div
                        className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${step === 'email-confirmation' || step === 'download-app'
                          ? 'bg-tertiary text-white'
                          : 'bg-gray-100 text-gray-500'
                          }`}
                      >
                        2
                      </div>
                    </li>
                    <li>
                      <div
                        className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${(step === 'download-app')
                          ? 'bg-tertiary text-white'
                          : 'bg-gray-100 text-gray-500'
                          }`}
                      >
                        3
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="px-4 py-8">
                <div className="max-w-md mx-auto">
                  {step === 'sign_up' && (
                    <>
                      <h1 className="text-3xl text-gray-800 font-bold mb-6 text-center">
                        Let's get started
                      </h1>
                      {isFromConference && (googleEnabled || microsoftEnabled) && (
                        <div>
                          <div className="flex flex-col sm:flex-row sm:justify-between justify-center sm:space-x-4 mb-6 mt-6">
                            {googleEnabled && <div className="w-full sm:w-1/2 flex justify-center mb-4 sm:mb-0">
                              <GoogleLogin
                                onSuccess={handleGoogleLogin}
                                text="signup_with"
                              />
                            </div>}
                            {microsoftEnabled && <div className="w-full sm:w-1/2 flex justify-center" data-testid="signUpWithMicrosoftContainer">
                              <button
                                onClick={handleMicrosoftLoginClick}
                                disabled={isAuthenticating}
                                className="border border-gray-300 rounded-md px-4 py-2 flex items-center justify-center text-sm font-medium"
                              >
                                <>
                                  <svg className="w-4 h-4 mr-2" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#f35325" d="M1 1h10v10H1z" />
                                    <path fill="#81bc06" d="M12 1h10v10H12z" />
                                    <path fill="#05a6f0" d="M1 12h10v10H1z" />
                                    <path fill="#ffba08" d="M12 12h10v10H12z" />
                                  </svg>
                                  {isAuthenticating ? 'Loading...' : 'Sign up with Microsoft'}
                                </>
                              </button>
                            </div>}
                          </div>
                        </div>
                      )}
                      {isFromConference && (googleEnabled || microsoftEnabled) && <p className="text-center font-bold mt-6 border-t border-gray-200 py-4">Or</p>}
                      <SignupForm
                        code={code}
                        onComplete={handleSignupComplete}
                      />
                      {signingUp && (
                        <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center">
                          <Loader color='black' />
                        </div>
                      )}
                    </>
                  )}
                  {step === 'email-confirmation' && signupMethod === 'email' && (
                    <EmailConfirmationScreen />
                  )}
                  {step === 'download-app' && (
                    <DownloadAppPrompt />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={OnboardingImage}
            width="760"
            height="1024"
            alt="Onboarding"
          />
        </div>
      </div>
    </main>
  );
}

export default ConferenceOnboarding;