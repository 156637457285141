import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import Transition from './Transition';
import { FieldOption } from '../data/models';
import ClickBackdrop, { clickBackdropZindex } from '../partials/utility/ClickBackdrop';
import { ColorChangeHandler, SketchPicker } from 'react-color';
import Button from '../partials/shared/Button';
import { Flex } from './Layout';
import AssetDropzone from '../partials/assets/AssetDropzone';

type Alignment = 'left' | 'right';
type Overflow = 'none' | 'hidden' | 'visible';

export type MB_VALUE = 0 | 0.5 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 12;

const MB_CLASSES = {
    0: 'mb-0',
    0.5: 'mb-0.5',
    1: 'mb-1',
    2: 'mb-2',
    3: 'mb-3',
    4: 'mb-4',
    5: 'mb-5',
    6: 'mb-6',
    7: 'mb-7',
    8: 'mb-8',
    12: 'mb-12',
}

export type MR_VALUE = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 12;

const MR_CLASSES = {
    0: 'mr-0',
    1: 'mr-1',
    2: 'mr-2',
    3: 'mr-3',
    4: 'mr-4',
    5: 'mr-5',
    6: 'mr-6',
    7: 'mr-7',
    8: 'mr-8',
    12: 'mr-12',
}

export type TEXT_SIZE_VALUE = 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl' | '8xl' | '9xl';

const TEXT_SIZE_CLASSES = {
    xs: 'text-xs',
    sm: 'text-sm',
    base: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
    '2xl': 'text-2xl',
    '3xl': 'text-3xl',
    '4xl': 'text-4xl',
    '5xl': 'text-5xl',
    '6xl': 'text-6xl',
    '7xl': 'text-7xl',
    '8xl': 'text-8xl',
    '9xl': 'text-9xl',
}

export type FONT_STYLE_VALUE = 'thin' | 'extralight' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extrabold' | 'black';

const FONT_STYLE_CLASSES = {
    thin: 'font-thin',
    extralight: 'font-extralight',
    light: 'font-light',
    normal: 'font-normal',
    medium: 'font-medium',
    semibold: 'font-semibold',
    bold: 'font-bold',
    extrabold: 'font-extrabold',
    black: 'font-black',
}

export type JUSTIFY_VALUE = 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
const JUSTIFY_CLASSES = {
    start: 'justify-start',
    end: 'justify-end',
    center: 'justify-center',
    between: 'justify-between',
    around: 'justify-around',
    evenly: 'justify-evenly',
}

export {
    MB_CLASSES
}

export const OVERFLOW_CONFIG = {
    'none': '',
    hidden: 'overflow-hidden',
    visible: 'overflow-visible'
}

const ColorBullet = ({ absolute, color, onClick }: { absolute?: boolean, color: string, onClick?: (e?: any) => void }) => <div className={`${!!onClick ? 'cursor-pointer' : ''} border shadow-sm w-5 h-5 rounded-full ${absolute ? 'mt-2 inset-0 right-auto absolute z-10' : ''}`} style={{
    backgroundColor: color,
}}
    onClick={onClick} />
const ColorPicker = ({ align = "left", color, onDone, onChange }: { align?: Alignment, color: string, onDone: () => void, onChange: ColorChangeHandler }) => <>
    <ClickBackdrop testId="tagColorBackdrop" onClick={onDone} />
    <div
        className={`origin-top-right ${clickBackdropZindex} absolute top-full ${align == "right" ? 'left-56' : 'left-0'
            } min-w-44 bg-white border border-gray-200 rounded shadow-lg overflow-hidden mt-1`}
    >
        <SketchPicker color={color} onChange={onChange} />
        <Button
            text="Done"
            color="secondary"
            className="w-full"
            rounded="rounded-b"
            onClick={onDone}
        />
    </div>
</>

const Label = ({ children, mb = 2, required = false, size = 'base', justify = 'start' }: { mb?: MB_VALUE, children: React.ReactNode, justify?: JUSTIFY_VALUE, required?: boolean, size?: TEXT_SIZE_VALUE }) => <p className={`font-semibold flex ${JUSTIFY_CLASSES[justify]} ${TEXT_SIZE_CLASSES[size]} ${MB_CLASSES[mb]}`}>{children}{required && <span className="ml-2 text-red-600">*</span>}</p>
const Group = ({ children, mb = 8, className = '', overflow = 'hidden' }: { mb?: MB_VALUE, children: React.ReactNode, className?: string, overflow?: Overflow }) => <div className={`relative ${MB_CLASSES[mb]} ${className} ${OVERFLOW_CONFIG[overflow]}`}>{children}</div>;
const InputGroup = ({ mb = 8, children, overflow = 'hidden' }: { children: React.ReactNode, mb?: MB_VALUE, overflow?: Overflow }) => <div className={`relative border-b border-gray-200 ${OVERFLOW_CONFIG[overflow]} ${MB_CLASSES[mb]}`}>{children}</div>
const SelectGroup = ({ children }: { children: React.ReactNode }) => <div className="overflow-hidden mb-8">{children}</div>
const Input = ({ autoFocus, disabled, error, name, required, type, placeholder, value, className, pl, onChange, onBlur, onFocus, ...rest }: {
    autoFocus?: boolean,
    error?: string,
    disabled?: boolean,
    type: string,
    placeholder?: string,
    name?: string,
    required?: boolean,
    value?: string | undefined,
    className?: string,
    pl?: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
}) => <><input
    disabled={disabled}
    autoFocus={autoFocus}
    type={type}
    placeholder={placeholder}
    value={value}
    name={name}
    required={required}
    onBlur={onBlur}
    onChange={onChange}
    onFocus={onFocus}
    className={className ? className : `px-2 ${pl} disabled:opacity-60 placeholder-gray-400 bg-transparent relative text-sm w-full focus:outline-none focus:ring-0 ${error ? 'border border-red-500' : 'border-0'}`}
    {...rest}
/>
        {error && <div className="text-xs mt-1 text-red-500">{error}</div>}
    </>

type FormSelectSize = 'xs' | 'default';
const FORM_SELECT_SIZES = {
    xs: 'form-select-xs',
    default: ''
}
const Select = ({ children, multiple = false, size = 'default', value, onChange, ...rest }: { children: React.ReactNode, multiple?: boolean, value?: string | undefined, size?: FormSelectSize, onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void }) => <select multiple={multiple} defaultValue={value} className={`form-select ${FORM_SELECT_SIZES[size]} w-full`} onChange={onChange} {...rest}>
    {children}
</select>

const HelperText = ({ mb = 0, children }: { mb?: MB_VALUE, children: React.ReactNode }) => <p className={`${MB_CLASSES[mb]} text-sm text-medium text-tertiary mt-2 italic`}>{children}</p>
const RequiredAsterik = ({ children, textSize = 'text-sm' }: { children?: React.ReactNode, textSize?: 'text-xxs' | 'text-xs' | 'text-sm' }) => <p className={`ml-2 text-red-500 ${textSize}`}>* {children}</p>

const TextArea = ({ mb = 8, placeholder, rows, value, onChange, ...rest }: { mb?: MB_VALUE, placeholder?: string, rows?: number, value?: string | undefined, onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void }) => <div className={`flex p-2 ${MB_CLASSES[mb]} border border-gray-200 rounded-md shadow bg-white`}>
    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" className="mt-2 text-gray-600" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M19 3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h8a.996.996 0 0 0 .707-.293l7-7a.997.997 0 0 0 .196-.293c.014-.03.022-.061.033-.093a.991.991 0 0 0 .051-.259c.002-.021.013-.041.013-.062V5c0-1.103-.897-2-2-2zM5 5h14v7h-6a1 1 0 0 0-1 1v6H5V5zm9 12.586V14h3.586L14 17.586z"></path></svg>
    <textarea
        name="description"
        placeholder={placeholder}
        className="bg-transparent focus:ring-0 px-2 placeholder-gray-500 relative text-xs border-0 w-full border-0 outline-none focus:outline-none"
        rows={rows}
        value={value}
        onChange={onChange}
        {...rest}
    />
</div>

type SWITCH_SIZE = 'sm' | 'md' | 'lg';
const SWITCH_SIZE_CLASSES = {
    sm: 'form-switch-sm',
    md: 'form-switch-md',
    lg: 'form-switch-lg',
}

const Switch = ({ checked, size, disabled = false, htmlFor, onClick, ...rest }: {
    checked: boolean, size?: SWITCH_SIZE, disabled?: boolean, htmlFor: string, onClick?: (e: React.MouseEvent<HTMLDivElement>) => void,
}) => <div
    className={`form-switch focus-within:shadow-outline mr-3 ${disabled ? 'opacity-60 pointer-events-none' : ''} ${size ? SWITCH_SIZE_CLASSES[size] : ''}`}
    role="switch"
    onClick={onClick}
    {...rest}
>
        <input
            type="checkbox"
            className="sr-only"
            id={htmlFor}
            checked={checked}
            disabled
        />
        <label
            className="bg-gray-300"
            htmlFor={htmlFor}
        >
            <span className="bg-white shadow-sm" aria-hidden="true"></span>
            <span className="sr-only"></span>
        </label>
    </div>

const SwitchLabel = ({ children, font = 'normal', mr }: { children: React.ReactNode, font?: FONT_STYLE_VALUE, mr?: MR_VALUE }) => <p className={`text-gray-800 text-sm ${FONT_STYLE_CLASSES[font]} ${mr ? MR_CLASSES[mr] : ''}`}>{children}</p>

const DropdownCheck = ({ selected }: { selected: boolean }) => <svg className={`flex-shrink-0 mr-2 fill-current text-secondary ${!selected && 'invisible'}`} width="12" height="9" viewBox="0 0 12 9">
    <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
</svg>

const DropdownOptionItem = ({ checkAlignment = 'left', children, option, isSelected, onClick }: {
    checkAlignment?: CheckAlignment,
    children?: React.ReactNode,
    option: DropdownOption | FieldOption,
    isSelected: boolean,
    onClick: () => void
}) => <button
    key={option.id}
    data-testid={`dropdownOption_${option.name}`}
    className={`flex focus:outline-none items-center ${checkAlignment === 'right' ? 'justify-between' : ''} w-full hover:bg-gray-50 py-1 px-3 cursor-pointer ${isSelected && 'text-secondary'}`}
    onClick={onClick}
>
        {checkAlignment === 'left' && <DropdownCheck selected={isSelected} />}
        <Flex>
            {option.name}
            {children}
        </Flex>
        {checkAlignment === 'right' && <DropdownCheck selected={isSelected} />}
    </button>

export type DropdownOption = {
    id: number,
    name: string,
    color?: string,
    addNewOption?: boolean
};

type CheckAlignment = 'left' | 'right';
type ButtonSize = 'xxs' | 'xs' | 'sm' | 'default';
const BUTTON_SIZES = {
    xxs: 'btn-xxs',
    xs: 'btn-xs',
    sm: 'btn-sm',
    default: 'btn'
}

const Dropdown = ({
    children,
    checkAlignment = 'left',
    options = [],
    multiple = false,
    fullWidth = false,
    fullHeight = false,
    selected = -1,
    size = 'default',
    testId = 'formDropdown',
    title,
    onSelect,
    element = DropdownOptionItem
}: {
    children?: React.ReactNode,
    checkAlignment?: CheckAlignment,
    options: DropdownOption[] | FieldOption[] | (DropdownOption | FieldOption)[],
    selected: number | number[],
    multiple?: boolean,
    fullWidth?: boolean,
    fullHeight?: boolean,
    size?: ButtonSize,
    testId?: string,
    title?: string,
    onSelect: (id: number) => void,
    element?: ({ checkAlignment, option, isSelected, onClick }: {
        checkAlignment?: CheckAlignment,
        option: DropdownOption | FieldOption,
        isSelected: boolean,
        onClick: () => void
    }) => React.ReactNode
}) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const trigger = useRef<HTMLButtonElement>(null);
    const dropdown = useRef<HTMLDivElement>(null);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }: {
            target: any
        }) => {
            if (!dropdown.current || !trigger.current) return;
            if (!dropdownOpen || dropdown.current?.contains(target) || trigger.current.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    }, [dropdown.current, trigger.current]);

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }: {
            keyCode: number
        }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    }, [dropdownOpen]);

    const selectedOptionTitle = () => {
        if (title) return title;
        if (multiple) {
            if (!(selected as number[]).length) return 'Select Option';
            if ((selected as number[]).length > 1) return 'Multiple';
            const firstSelection = options.find(({ id }) => id === (selected as number[])[0]);
            return firstSelection?.name;
        }
        const foundOption = options.find(({ id }) => id === selected);
        return foundOption?.name || 'Select Option';
    }

    return <>
        {dropdownOpen && <ClickBackdrop onClick={() => setDropdownOpen(false)} />}
        <div className={`relative inline-flex ${fullWidth ? 'w-full' : ''} ${fullHeight ? 'h-full' : ''}`} data-testId={testId}>
            <button
                ref={trigger}
                className={`${BUTTON_SIZES[size]} focus:outline-none justify-between min-w-44 ${fullWidth ? 'w-full' : ''} bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600`}
                aria-label="Select date range"
                aria-haspopup="true"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-expanded={dropdownOpen}
            >
                <span className="flex items-center">
                    <span>{selectedOptionTitle()}</span>
                </span>
                <svg className="flex-shrink-0 ml-1 fill-current text-gray-400" width="11" height="7" viewBox="0 0 11 7">
                    <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
                </svg>
            </button>
            <Transition
                show={dropdownOpen}
                tag="div"
                className={`${clickBackdropZindex} absolute top-full left-0 w-full bg-white border border-gray-200 rounded shadow-lg overflow-y-auto max-h-72 mt-1`}
                enter="transition ease-out duration-100 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-100"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
                appear={undefined}
            >
                <div
                    ref={dropdown}
                    className="font-medium text-sm text-gray-600"
                    onFocus={() => setDropdownOpen(true)}
                    onBlur={() => setDropdownOpen(false)}
                >
                    {children}
                    {
                        options.map((option) => element({
                            checkAlignment,
                            option,
                            isSelected: multiple ? (selected as number[]).includes(option.id) : option.id === selected,
                            onClick: () => {
                                onSelect?.(option.id)
                                if (!multiple) setDropdownOpen(false)
                            }
                        }))
                    }
                </div>
            </Transition>
        </div>
    </>;
}

const Form = {
    ColorBullet,
    ColorPicker,
    Dropdown,
    DropdownCheck,
    DropdownOptionItem,
    Label,
    FileDropzone: AssetDropzone,
    Group,
    HelperText,
    InputGroup,
    Input,
    RequiredAsterik,
    Select,
    SelectGroup,
    Switch,
    SwitchLabel,
    TextArea
}

export default Form;
