import EntryNote from '../entries/EntryNote';
import EntryNotePhotos from '../entries/EntryNotePhotos';
import { EntryGroup, EntryNotesComponentName, Note, Report } from '../../data/models';
import { EntryGroupContainer, EntryGroupHeader, EntryGroupLoader, shouldShowHeader } from '../report_page_entry_groups/ReportPageEntryGroup';
import { useParams } from 'react-router-dom';
import useReportContext from '../reports/useReportContext';
import usePageEntries from '../report_page_entry_groups/hooks/usePageEntries';
import ProjectNotesModal from '../project/ProjectNotesModal';
import useEntryGroup from './useEntryGroup';
import useReport from '../reports/hooks/useReport';
import { useEffect, useState } from 'react';
import useAssets from '../assets/hooks/useAssets';
import { photosLoadedClass } from '../entries/EntryPin';
import PhotosModal from '../utility/PhotosModal';
import NoteCreateModal, { NoteAddButton } from '../notes/NoteCreateModal';
import NoteEditModal from '../notes/NoteEditModal';
import useFeature from '../feature_flags/hooks/useFeature';

const ENTRY_NOTE_COMPONENTS = {
  EntryNote,
  EntryNotePhotos,
};

function EntryGroupNotes({
  entryGroup,
  pageEntryGroupObjectId,
  lastGroup,
  report,
  splitEntryGroup
}: {
  report: Report;
  entryGroup: EntryGroup;
  pageEntryGroupObjectId: string;
  lastGroup: boolean;
  splitEntryGroup: boolean;
}) {

  const { share_id } = useParams<{ share_id: string | undefined }>();
  const { editing } = useReportContext();
  const { latestEntryGroup, resetEntryGroup, loading: loadingEntryGroup, reseting } = useEntryGroup(entryGroup.objectId, {
    share_id,
  })
  const { updateReport, latestReport, updatedReport, updating, loading: loadingReport } = useReport(report.objectId, {
    share_id
  })

  const {
    entries,
    loading: loadingEntries,
    loadEntries,
  } = usePageEntries(pageEntryGroupObjectId, {
    share_id,
  });

  const {
    assets,
    loading: loadingAssets,
    error: assetsError,
  } = useAssets(entries?.[0]?.value, 'note', { share_id });

  const [shownPhotoIndex, setShownPhotoIndex] = useState<number>(-1);
  const [loadedPhotos, setLoadedPhotos] = useState<number[]>([]);
  const [creatingNote, setCreatingNote] = useState<boolean>(false);
  const [editingNote, setEditingNote] = useState<Note | null>(null);
  const hasEntryNote = entries.some((entry) => entry.component_name === 'EntryNote');
  const photosEntries = entries.filter((entry) => entry.component_name === 'EntryNotePhotos');
  const photosLength = photosEntries.map((entry) => (entry.info?.end_index || 0) - (entry.info?.start_index || 0)).reduce((a, b) => a + b, 0);
  const allPhotosLoaded = (loadedPhotos.length === photosLength);
  const { enabled } = useFeature('note_dynamic_resize')

  useEffect(() => {
    if (!updatedReport) return;
    resetEntryGroup();
  }, [updatedReport?.updated_at])

  useEffect(() => {
    loadEntries();
  }, [latestEntryGroup?.updated_at])

  const showLoader = loadingReport || (loadingEntryGroup && !share_id) || loadingAssets || loadingEntries || reseting || updating;

  return <>

    <EntryGroupContainer lastGroup={lastGroup} entryGroup={entryGroup}>
      {allPhotosLoaded && <span data-testid="siteNotePhotosLoaded" className={photosLoadedClass} />}
      <EntryGroupHeader entryGroup={entryGroup} editing={editing} splitEntryGroup={splitEntryGroup}>
        {(hasEntryNote && editing && enabled) && <div className="flex space-x-2">
          <ProjectNotesModal buttonText="Select Note" mode="selection" onSelect={(newNote: any) => {
            updateReport({
              report_notes_attributes: [{
                id: latestReport.report_notes?.[0]?.id,
                _destroy: true
              }, newNote]
            });
          }} />
          <NoteAddButton onClick={() => setCreatingNote(true)} />
        </div>}
      </EntryGroupHeader>
      {showLoader ? (
        <EntryGroupLoader />
      ) : (
        <Container>
          {entries.map((entry) => {
            const { component_name, value } = entry;
            const EntryNoteComponent = ENTRY_NOTE_COMPONENTS[component_name as EntryNotesComponentName];
            if (!EntryNoteComponent) return null;
            const filteredAssets = component_name === 'EntryNotePhotos' ? assets.slice(entry.info?.start_index || 0, entry.info?.end_index || assets.length) : [];
            return <EntryNoteComponent
              key={`report_${report.objectId}_entry_note_${value}`}
              noteId={value}
              entry={entry}
              onEditButtonClicked={enabled ? setEditingNote : undefined}
              assets={filteredAssets}
              loading={loadingAssets}
              error={assetsError}
              onPhotoSelected={(index) => setShownPhotoIndex(index)}
              onPhotoLoaded={(i) => setLoadedPhotos((loadedIndexes) => [...loadedIndexes, i])}
            />
          })}
          {entries.length === 0 && <p className="px-2 pb-2">N/A</p>}
        </Container>)}
      {!!photosEntries.length && <PhotosModal
        isOpen={shownPhotoIndex > -1}
        assets={assets}
        activeIndex={shownPhotoIndex}
        hideBackdrop={true}
        onClose={() => setShownPhotoIndex(-1)}
      />}
    </EntryGroupContainer>

    <NoteCreateModal
      open={creatingNote}
      onClose={() => setCreatingNote(false)}
      afterAdd={(newNote: any) => {
        updateReport({
          report_notes_attributes: [{
            id: latestReport.report_notes?.[0]?.id,
            _destroy: true
          }, {
            note_id: newNote.id
          }]
        });
      }} />
    <NoteEditModal
      noteId={editingNote?.objectId || null}
      onClose={() => setEditingNote(null)}
      afterEdit={resetEntryGroup}
    />
  </>;
}

const Container = ({ children }: {
  children: React.ReactNode
}) => <div className="px-2 py-2 text-xs">{children}</div>

export default EntryGroupNotes;
